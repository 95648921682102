import * as React from "react";

import { Box } from "@components/box";
import { GatsbyLink } from "@components/gatsby-link";
import { PageLayout, SEO } from "@components/page-layout";
import { Button } from "@brainfinance/icash-component-library";
import { GrayPhrase, PageHeading, Paragraph } from "@components/typography";
import { MapContact } from "@containers/map";

const SUPPORT_EMAIL = 'support@icash.ca';

const mailToSupport = () => {
  window.location.href = `mailto:${SUPPORT_EMAIL}`;
};

const ContactUs = () => (
  <PageLayout>
    <SEO title="Contact us" />
    <Box className="text-center mx-auto max-w-[660px] mb-[4.125rem] mt-[8rem] md:mt-[5.5rem]">
      <PageHeading className="!w-full mb-[22px]">Contact us</PageHeading>
      <Paragraph className="mb-[4.125rem]">
        <GrayPhrase>
          Let us know how we can better help you.
          <br />
          Share your experience and let us know how we can improve our services.
        </GrayPhrase>
      </Paragraph>
      <Box className="text-[19px] font-700 mb-[2rem]">Get in touch with our support specialist</Box>
      <Button appearance="primary" className="w-full max-w-[440px]" onClick={mailToSupport}>
        Send an email
      </Button>
    </Box>
    <Box className="bg-interface-100 mx-auto max-w-[935px] rounded-[50px] p-[2.75rem] mb-[5.5rem] md:mx-[22px]">
      <GrayPhrase className="inline-block text-[15px] tracking-[-0.25px] leading-[20px]">
        Scammers occasionally use our name, so please be aware that we never ask for money up front.
        If you’re contacted by someone claiming to be from iCash and you have doubts, please DO NOT
        send that person information or money. Instead, contact us by email:{" "}
        <a className="text-primary hover:text-primary-emphasis font-500" href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
        <br />
        If you've been targeted or want to learn about how to protect yourself against fraud and
        scam, read our articles: <GatsbyLink to="#">Fraud Protection FAQs</GatsbyLink>, Warning
        Signs of Lending Scams: <GatsbyLink to="#">Keep Yourself Protected</GatsbyLink>.
      </GrayPhrase>
    </Box>
    <MapContact />
    <Box className="h-[14px] sm:h-0"></Box>
  </PageLayout>
);

export default ContactUs;
